import React from "react";
import {observer} from "mobx-react";
import history from "../../helper/browserHistory"

import {checkInput} from "../../components/userManagement/userManagementUtil";
import {observable} from "mobx";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";
import userStore from "../../components/userManagement/userStore";

@observer
class RegisterPage extends React.Component {

    @observable
    users = [];

    @observable
    errorNewUser = false;

    @observable
    user = {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        passwordRepeat: "",
        admin: false,
        roles: [],
        rolesEdit: false
    };

    //Beim Schließen des Modals, werden die Werte auf 0 (bzw. bei Strings auf "") zurückgesetzt, damit beim erneuten
    // Öffnen des Modals nicht die alten Werte in die Felder übernommen werden.
    closeInput() {
        this.user = {
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            password: "",
            passwordRepeat: "",
            id: 0
        };

        this.loadingNewUser = false;
    }

    //Die im Modal eingetragenen Daten werden in diese Funktion übergeben. Es wird mit Hilfe des Typs festgestellt,
    // welchen Wert man verändern/beschreiben möchte. Wurde der Typ erkannt wird die observable Variable mit dem
    // eingegebenen Wert überschrieben
    handleInputChange(name, e) {
        return this.user[name] = e.target.value;
    }

    render() {
        const {i18n} = this.props;
        return (
            <div>
                <Segment>
                    <h1>{i18n.t("login:user.register")}</h1>
                    <Form error={this.errorNewUser}>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{i18n.t("login:user.first_name")}</label>
                                <Form.Input fluid value={this.user.firstName}
                                            onChange={e => this.handleInputChange('firstName', e)}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t("login:user.last_name")}</label>
                                <Form.Input fluid value={this.user.lastName}
                                            onChange={e => this.handleInputChange('lastName', e)}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{i18n.t("login:user.username")}</label>
                                <Form.Input fluid value={this.user.username}
                                            onChange={e => this.handleInputChange('username', e)}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t("login:user.email")}</label>
                                <Form.Input fluid value={this.user.email}
                                            type='email'
                                            onChange={e => this.handleInputChange('email', e)}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{i18n.t("login:user.password")}</label>
                                <Form.Input fluid value={this.user.password}
                                            type='password'
                                            onChange={e => this.handleInputChange('password', e)}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t("login:user.confirm_password")}</label>
                                <Form.Input fluid value={this.user.passwordRepeat}
                                            type='password'
                                            onChange={e => this.handleInputChange('passwordRepeat', e)}/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <Button onClick={() => {
                        if (checkInput(this.user)) {
                            userStore.registerUser(this.user).then((res) => {
                                    if (res.success) {
                                        history.push('/login');
                                    } else {
                                        alert(res.message)
                                    }
                                }
                            );
                        } else {
                            alert(i18n.t("alert.valid_add_new_user"));
                        }
                    }}
                            type='submit'>{i18n.t("login:user.register")}</Button>
                    <Button floated={"right"} onClick={()=>history.push("/login")}>{i18n.t("common:common.back")}</Button>
                </Segment>
            </div>
        );
    }
}

export default withTranslation(['login', 'common'])(RegisterPage)